'use strict';
import * as smoothScroll from '@elements/smooth-scroll';

let options = {
    offset: 16
}

export function init() {
    smoothScroll.init(options);
}

export function initInScope($scope){
    smoothScroll.initInScope($scope);

    const $links = $scope.find('.js-smoothscroll__link');

    $links.on('click', (e) => {
        e.stopImmediatePropagation();
        smoothScroll.setOffset(16)
    })
}


