import $ from 'jquery';

// import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as smoothScroll from './scripts/smooth-scroll';
app.smoothScroll = smoothScroll.init();
app.modules.smoothScroll = smoothScroll.initInScope;

import * as anchorNav from '@elements/anchor-nav';
app.modules.anchorNav  = anchorNav.createInitInScope({
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
},  {
    renderItem: function (text, anchor) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
    },
    smoothScroll: true,
    scrollSpy: true,
    scrollSpyOffset: 100
});

// use all the standards with the js-slider class
import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

// provide you own js-class and custom options
app.modules.teaserSlider = slider.createInitInScope('.js-teaser-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow teaser-slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-elements icon-rotate-180"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow teaser-slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-elements"></span></button>',
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        },
    ]
});

app.modules.gallerySlider = slider.createInitInScope('.js-gallery-slider', {
    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow gallery-slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-elements icon-rotate-180"></span></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow gallery-slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-elements"></span></button>',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false
});

import * as changeOpacity from './scripts/change-opacity';
app.modules.changeOpacity = changeOpacity.initInScope;

import * as delayedfadeIn from './scripts/delayed-fade-in';
app.modules.delayedfadeIn = delayedfadeIn.initInScope;

import { showCookieBar } from "@elements/cookie-permissions";
if (!_config.admin && !_config.editmode) {
    showCookieBar();
}

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);