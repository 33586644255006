import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    if (!matchMedia('(max-width: 767px)').matches) {
        add(
            $scope.find('.js-delayed-fade-in'),
            calculateAnimationProgress,
            setAnimationProgress
        );
    }
}

function calculateAnimationProgress(element) {
    const rect = element.getBoundingClientRect();
    const start = window.innerHeight;
    const end = rect.height * -1;

    let progress = (rect.top - end) / (start - end);
    let acceleration = rect.left / window.innerWidth;

    return 1 - Math.max(
        Math.min(
            progress * (1 + (acceleration - (acceleration * progress * 2))),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    element.style.opacity = Math.max(.3, Math.min(1, progress * 2));
    element.style.transform = `translateY(${((progress * 20) - 10) * -1}rem)`;
}